import { Avatar, Button, Divider, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { useCustomViewer } from '../hooks/useCustomViewer'
import { RELATIONSHIPS } from '../utils/RelationsihpTypes'
import { acceptRequest, denyRequest, removeConnection, requestConnection } from '../domain/connectionActions'
import {
  acceptDependentLinkRequest,
  denyDependentLinkRequest,
  removeDependentLink,
  requestDependentLink,
} from '../domain/dependentLinkActions'

export enum SEARCH_TYPES {
  CONNECTIONS,
  DEPENDENT_LINK,
}

export const SearchListItem = ({ user, searchType }: { user: any; searchType: SEARCH_TYPES }) => {
  const customViewer = useCustomViewer()

  const onRequestConnection = async (connectionId: string) => {
    if (customViewer) {
      switch (searchType) {
        case SEARCH_TYPES.CONNECTIONS: {
          requestConnection(customViewer.uid, connectionId)
          break
        }
        case SEARCH_TYPES.DEPENDENT_LINK: {
          requestDependentLink(customViewer.uid, connectionId)
          break
        }
      }
    }
  }

  const onAcceptRequest = async (uidToAccept: string) => {
    if (customViewer) {
      switch (searchType) {
        case SEARCH_TYPES.CONNECTIONS: {
          acceptRequest(customViewer.uid, customViewer?.incomingConnectionRequests, uidToAccept)
          break
        }
        case SEARCH_TYPES.DEPENDENT_LINK: {
          acceptDependentLinkRequest(customViewer.uid, customViewer?.incomingDependentLinkRequests, uidToAccept)
          break
        }
      }
    }
  }

  const onDenyRequest = async (uidToDeny: string) => {
    if (customViewer) {
      switch (searchType) {
        case SEARCH_TYPES.CONNECTIONS: {
          denyRequest(customViewer.uid, customViewer?.incomingConnectionRequests, uidToDeny)
          break
        }
        case SEARCH_TYPES.DEPENDENT_LINK: {
          denyDependentLinkRequest(customViewer.uid, customViewer?.incomingDependentLinkRequests, uidToDeny)
          break
        }
      }
    }
  }

  const onRemoveConnection = async (uidToRemove: string) => {
    if (customViewer) {
      switch (searchType) {
        case SEARCH_TYPES.CONNECTIONS: {
          removeConnection(customViewer.uid, customViewer?.connections, uidToRemove)
          break
        }
        case SEARCH_TYPES.DEPENDENT_LINK: {
          removeDependentLink(customViewer.uid, customViewer?.dependentLinks, uidToRemove)
          break
        }
      }
    }
  }

  const relationship = (() => {
    if (customViewer?.uid === user.uid) {
      return RELATIONSHIPS.SELF
    } else if (
      (searchType === SEARCH_TYPES.CONNECTIONS &&
        customViewer.outgoingConnectionRequests.some(({ uid }: { uid: string }) => user.uid === uid)) ||
      (searchType === SEARCH_TYPES.DEPENDENT_LINK &&
        customViewer.outgoingDependentLinkRequests.some(({ uid }: { uid: string }) => user.uid === uid))
    ) {
      return RELATIONSHIPS.REQUESTED_OUT
    } else if (
      (searchType === SEARCH_TYPES.CONNECTIONS &&
        customViewer.incomingConnectionRequests.some(({ uid }: { uid: string }) => user.uid === uid)) ||
      (searchType === SEARCH_TYPES.DEPENDENT_LINK &&
        customViewer.incomingDependentLinkRequests.some(({ uid }: { uid: string }) => user.uid === uid))
    ) {
      return RELATIONSHIPS.REQUESTED_IN
    } else if (
      (searchType === SEARCH_TYPES.CONNECTIONS &&
        customViewer.connections.some(({ uid }: { uid: string }) => user.uid === uid)) ||
      (searchType === SEARCH_TYPES.DEPENDENT_LINK &&
        customViewer.dependentLinks.some(({ uid }: { uid: string }) => user.uid === uid))
    ) {
      return RELATIONSHIPS.CONNECTED
    } else {
      return RELATIONSHIPS.NONE
    }
  })()

  const includeActionPanel = [RELATIONSHIPS.REQUESTED_IN, RELATIONSHIPS.CONNECTED, RELATIONSHIPS.NONE].includes(
    relationship
  )

  const secondaryText = {
    [SEARCH_TYPES.CONNECTIONS]: {
      [RELATIONSHIPS.CONNECTED]: `You're Connected!`,
      [RELATIONSHIPS.SELF]: `It's You!`,
      [RELATIONSHIPS.NONE]: null,
      [RELATIONSHIPS.REQUESTED_OUT]: `Request Sent!`,
      [RELATIONSHIPS.REQUESTED_IN]: null,
    },
    [SEARCH_TYPES.DEPENDENT_LINK]: {
      [RELATIONSHIPS.CONNECTED]: `Dependents Linked!`,
      [RELATIONSHIPS.SELF]: `It's You!`,
      [RELATIONSHIPS.NONE]: null,
      [RELATIONSHIPS.REQUESTED_OUT]: `Link Request Sent!`,
      [RELATIONSHIPS.REQUESTED_IN]: null,
    },
  }

  const actionButtonTexts = {
    [SEARCH_TYPES.CONNECTIONS]: {
      accept: 'Accept Request',
      deny: 'Deny Request',
      disconnect: 'Disconnect',
      connect: 'Connect',
    },
    [SEARCH_TYPES.DEPENDENT_LINK]: {
      accept: 'Accept Dependent Link',
      deny: 'Deny Dependent Link',
      disconnect: 'Unlink Dependents',
      connect: 'Link Dependents',
    },
  }

  return (
    <>
      <ListItem
        key={`SearchResult-${user.uid}`}
        style={{
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'flex-start',
          marginBottom: includeActionPanel ? '16px' : '0px',
          width: '250px',
        }}
      >
        <Link
          to={`/publicProfile/${user.uid}`}
          style={{ textDecoration: 'none', color: 'black', display: 'flex', marginBottom: '5px' }}
        >
          <ListItemAvatar style={{ alignSelf: 'center' }}>
            <Avatar src={user.photoURL || ''} alt={'Photo'} />
          </ListItemAvatar>
          <ListItemText primary={user.displayName} secondary={secondaryText[searchType][relationship]} />
        </Link>
        {includeActionPanel && (
          <div style={{ display: 'flex', flexFlow: 'column nowrap', width: '100%' }}>
            {relationship === RELATIONSHIPS.REQUESTED_IN && (
              <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
                <Button
                  size={'small'}
                  variant={'contained'}
                  color={'primary'}
                  onClick={(e) => {
                    e.preventDefault()
                    onAcceptRequest(user.uid)
                  }}
                  style={{ marginBottom: '8px' }}
                >
                  {actionButtonTexts[searchType].accept}
                </Button>
                <Button
                  size={'small'}
                  variant={'contained'}
                  color={'secondary'}
                  onClick={(e) => {
                    e.preventDefault()
                    onDenyRequest(user.uid)
                  }}
                >
                  {actionButtonTexts[searchType].deny}
                </Button>
              </div>
            )}

            {relationship === RELATIONSHIPS.CONNECTED && (
              <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
                <Button
                  size={'small'}
                  variant={'contained'}
                  color={'primary'}
                  onClick={(e) => {
                    e.preventDefault()
                    onRemoveConnection(user.uid)
                  }}
                >
                  {actionButtonTexts[searchType].disconnect}
                </Button>
              </div>
            )}

            {relationship === RELATIONSHIPS.NONE && (
              <Button
                size={'small'}
                variant={'contained'}
                color={'primary'}
                onClick={(e) => {
                  e.preventDefault()
                  onRequestConnection(user.uid)
                }}
              >
                {actionButtonTexts[searchType].connect}
              </Button>
            )}
          </div>
        )}
      </ListItem>
      <Divider style={{ width: '250px' }} />
    </>
  )
}
