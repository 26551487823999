import { List, ListItem, ListItemIcon, ListItemText, useTheme } from '@material-ui/core'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useFirebaseViewer } from '../hooks/useFirebaseViewer'

function ListItemLink(props: any) {
  const { icon, primary, to } = props

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref: any) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to]
  )

  return (
    <ListItem button component={renderLink}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} style={{ color: props.color }} />
    </ListItem>
  )
}

export const Footer = (props: any) => {
  const theme = useTheme()
  const firebaseViewer = useFirebaseViewer()

  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '20px',
        width: '100%',
      }}
    >
      <div>
        <List style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'flex-start', width: '100%' }}>
          <ListItemLink primary={'Home'} to='/home' color={theme.palette.secondary.main} />
          {!!firebaseViewer && (
            <ListItemLink primary={'Dashboard'} to='/dashboard' color={theme.palette.secondary.main} />
          )}
          <ListItemLink primary={'Leave Feedback'} to='/leaveFeedback' color={theme.palette.secondary.main} />
        </List>
      </div>
    </div>
  )
}
