export enum ITEM_CATEGORIES {
  SHOES = 'Shoes',
  APPAREL = 'Apparel',
  ACCESSORIES = 'Accessories',
  BODY = 'Body Measurements',
  DOGS = 'Dogs',
  CATS = 'Cats',
  HORSES = 'Horses',
}

export const accessories = [
  {
    name: 'Ring',
    category: ITEM_CATEGORIES.ACCESSORIES,
  },
  {
    name: 'Belt',
    category: ITEM_CATEGORIES.ACCESSORIES,
  },
  {
    name: 'Hat',
    category: ITEM_CATEGORIES.ACCESSORIES,
  },
  {
    name: 'Piercing Gauge',
    category: ITEM_CATEGORIES.ACCESSORIES,
  },
  {
    name: 'Necklace Chain',
    category: ITEM_CATEGORIES.ACCESSORIES,
  },
  {
    name: 'Watch Size',
    category: ITEM_CATEGORIES.ACCESSORIES,
  },
]

export const shoes = [
  {
    name: 'Sneakers',
    category: ITEM_CATEGORIES.SHOES,
  },
  {
    name: 'Flats',
    category: ITEM_CATEGORIES.SHOES,
  },
  {
    name: 'Flippy Floppies',
    category: ITEM_CATEGORIES.SHOES,
  },
  {
    name: 'Pumps/Heels',
    category: ITEM_CATEGORIES.SHOES,
  },
  {
    name: 'Dress Shoes/Boots',
    category: ITEM_CATEGORIES.SHOES,
  },
  {
    name: 'Booties',
    category: ITEM_CATEGORIES.SHOES,
  },
  {
    name: 'Winter Boots',
    category: ITEM_CATEGORIES.SHOES,
  },
  {
    name: 'Work Boots',
    category: ITEM_CATEGORIES.SHOES,
  },
  {
    name: 'Slippers',
    category: ITEM_CATEGORIES.SHOES,
  },
]

export const apparel = [
  {
    name: 'Bra',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Sports Bra',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Underwear',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Socks',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Tank Top',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Short Sleeve T-Shirt',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Long Sleeve T-Shirt',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Crew Neck Sweatshirt',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Hoodie',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Winter Jacket',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Jacket',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Shorts',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Athletic Shorts',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Leggings',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Sweatpants',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Dress Pants',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Dress',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Suit Coat',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Jeans',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Snow Pants',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Bibs/Overalls',
    category: ITEM_CATEGORIES.APPAREL,
  },
  {
    name: 'Long Johns/Long Underwear',
    category: ITEM_CATEGORIES.APPAREL,
  },
]

export const body = [
  {
    name: 'Wrist Size',
    category: ITEM_CATEGORIES.BODY,
  },
  {
    name: 'Waist Size',
    category: ITEM_CATEGORIES.BODY,
  },
  {
    name: 'Hips Size',
    category: ITEM_CATEGORIES.BODY,
  },
  {
    name: 'Bust Size',
    category: ITEM_CATEGORIES.BODY,
  },
  {
    name: 'Ribs Size',
    category: ITEM_CATEGORIES.BODY,
  },
  {
    name: 'Neck Size',
    category: ITEM_CATEGORIES.BODY,
  },
  {
    name: 'In Seam',
    category: ITEM_CATEGORIES.BODY,
  },
  {
    name: 'Sleeve Length',
    category: ITEM_CATEGORIES.BODY,
  },
]

export const dogs = [
  {
    name: 'Collar Size',
    category: ITEM_CATEGORIES.DOGS,
  },
  {
    name: 'Vest',
    category: ITEM_CATEGORIES.DOGS,
  },
  {
    name: 'Sweater',
    category: ITEM_CATEGORIES.DOGS,
  },
]

export const cats = [
  {
    name: 'Collar Size',
    category: ITEM_CATEGORIES.CATS,
  },
  {
    name: 'Vest',
    category: ITEM_CATEGORIES.CATS,
  },
  {
    name: 'Sweater',
    category: ITEM_CATEGORIES.CATS,
  },
]

export const horses = [
  {
    name: 'Blanket Size',
    category: ITEM_CATEGORIES.HORSES,
  },
]

export const humanItemOptions = [...accessories, ...apparel, ...body, ...shoes]
