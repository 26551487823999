import { Card, Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import React from 'react'

export const HomeScreen = () => {
  return (
    <>
      <Helmet>
        <title>Home | Sizably</title>
      </Helmet>
      <Card style={{ margin: '30px', padding: '20px' }}>
        <Typography variant={'h4'} color={'primary'}>
          Welcome To Sizably!
        </Typography>
        <Typography variant={'body1'} color={'textSecondary'}>
          Where Size Matters
        </Typography>
        <Divider style={{ marginBottom: '20px' }} />
        <List>
          <ListItemText primary={'What To Do'} />
          <ListItem>
            <ListItemText primary={'Sign in with Google.'} />
          </ListItem>
          <ListItem>
            <ListItemText primary={'Add some items with your sizes to fill out your profile.'} />
          </ListItem>
          <ListItem>
            <ListItemText primary={'Search for your friends and connect with them.'} />
          </ListItem>
          <ListItem>
            <ListItemText primary={'Use this for finding sizes for gifts, or anything else you can think of.'} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                'Please, send me your feedback on this platform (good or bad!) I want to make it as useful as possible!'
              }
            />
          </ListItem>
        </List>
      </Card>
    </>
  )
}
