import { Card, Divider, Typography } from '@material-ui/core'
import React from 'react'
import { useCustomViewer } from '../../hooks/useCustomViewer'
import { Search } from '../Search'
import { SEARCH_TYPES } from '../SearchListItem'
import { AddDependentForm } from '../AddDependentForm'
import { DependentList } from '../DependentList'

export const DependentsCard = () => {
  const customViewer = useCustomViewer()

  return (
    <>
      {customViewer?.firebaseViewer && (
        <>
          <Card
            style={{
              display: 'flex',
              flexFlow: 'column nowrap',
              padding: '20px',
              margin: '30px',
            }}
          >
            <>
              <AddDependentForm />

              <div style={{ marginTop: '20px' }} />

              <DependentList />

              <Typography variant={'h4'} color={'primary'} style={{ marginTop: '20px' }}>
                Link Dependents With Another User
              </Typography>
              <Divider style={{ marginBottom: '20px' }} />
              <Typography variant={'body1'} style={{ textAlign: 'left' }} color={'textSecondary'}>
                {`The purpose of this is to link your dependents with others who share your dependents. This way, they can be added once, and managed by whoever shares the responsibility of being a dependency to them.`}
              </Typography>
              <Search searchType={SEARCH_TYPES.DEPENDENT_LINK} />
            </>
          </Card>
        </>
      )}
    </>
  )
}
