import { chunkFirestoreResults } from '../utils/chunkFirestoreResults'
import { firestore } from '../utils/firebase'
import { getDependentsByOwnerUid } from './getDependentsByOwnerUid'

export const getLinkedDependentsByUid = async (uid: string) => {
  const dependentLinksDocs = await firestore.collection(`users/${uid}/dependentLinks`).get()

  if (dependentLinksDocs.docs.length) {
    const dependentLinkResults = await chunkFirestoreResults(
      'users',
      'uid',
      dependentLinksDocs?.docs.map((doc) => doc.data())
    )

    const dependentsData: any[] = await Promise.all(
      dependentLinkResults.map((dependentParentProfile) => getDependentsByOwnerUid(uid, dependentParentProfile.uid))
    )

    return dependentsData
      .reduce((acc, cur) => acc.concat(cur), [])
      .map((dependent: any) => ({
        ...dependent,
        ...dependentLinkResults.find((x) => x.uid === dependent.ownerId),
      }))
  }

  return []
}
