import { Avatar, Button, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { acceptRequest, denyRequest } from '../domain/connectionActions'
import { acceptDependentLinkRequest, denyDependentLinkRequest } from '../domain/dependentLinkActions'
import { useCustomViewer } from '../hooks/useCustomViewer'
import { SEARCH_TYPES } from './SearchListItem'

export const RequestListItem = ({ user, type }: { user: any; type: SEARCH_TYPES }) => {
  const customViewer = useCustomViewer()

  const onAcceptRequest = async (uidToAccept: string) => {
    if (customViewer) {
      switch (type) {
        case SEARCH_TYPES.CONNECTIONS: {
          acceptRequest(customViewer.uid, customViewer?.incomingConnectionRequests, uidToAccept)
          break
        }
        case SEARCH_TYPES.DEPENDENT_LINK: {
          acceptDependentLinkRequest(customViewer.uid, customViewer?.incomingDependentLinkRequests, uidToAccept)
          break
        }
      }
    }
  }

  const onDenyRequest = async (uidToDeny: string) => {
    if (customViewer) {
      switch (type) {
        case SEARCH_TYPES.CONNECTIONS: {
          denyRequest(customViewer.uid, customViewer?.incomingConnectionRequests, uidToDeny)
          break
        }
        case SEARCH_TYPES.DEPENDENT_LINK: {
          denyDependentLinkRequest(customViewer.uid, customViewer?.incomingDependentLinkRequests, uidToDeny)
          break
        }
      }
    }
  }

  return (
    <ListItem
      key={`SearchResult-${user.uid}`}
      style={{
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'flex-start',
        marginBottom: '16px',
        maxWidth: '250px',
      }}
    >
      <Link
        to={`/publicProfile/${user.uid}`}
        style={{
          textDecoration: 'none',
          color: 'black',
          display: 'flex',
          marginBottom: '5px',
          width: '100%',
        }}
      >
        <ListItemAvatar style={{ alignSelf: 'center' }}>
          <Avatar src={user.photoURL || ''} alt={'Photo'} />
        </ListItemAvatar>
        <ListItemText
          primary={user.displayName}
          style={{ flex: 1, textOverflow: 'ellipsis', whiteSpace: 'normal', overflow: 'hidden' }}
        />
      </Link>
      <div style={{ display: 'flex', flexFlow: 'column nowrap', width: '100%' }}>
        <Button
          size={'small'}
          variant={'contained'}
          color={'primary'}
          onClick={(e) => {
            e.preventDefault()
            onAcceptRequest(user.uid)
          }}
          style={{ marginBottom: '8px' }}
        >
          {type === SEARCH_TYPES.CONNECTIONS ? 'Accept Request' : 'Accept Dependent Link'}
        </Button>
        <Button
          size={'small'}
          variant={'contained'}
          color={'secondary'}
          onClick={(e) => {
            e.preventDefault()
            onDenyRequest(user.uid)
          }}
        >
          Deny Request
        </Button>
      </div>
    </ListItem>
  )
}
