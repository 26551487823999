import { Avatar, Button, Card, Typography, useTheme } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import React, { useEffect, useState } from 'react'
import { useCustomViewer } from '../hooks/useCustomViewer'
import { SizesList } from '../components/cards/SizesList'
import { ProfileConnections } from '../components/ProfileConnections'
import { firestore, signInWithGoogle } from '../utils/firebase'
import { RELATIONSHIPS } from '../utils/RelationsihpTypes'
import { Link } from 'react-router-dom'
import { acceptRequest, denyRequest, removeConnection, requestConnection } from '../domain/connectionActions'
import { getDependentsByOwnerUid } from '../domain/getDependentsByOwnerUid'
import { DependentList } from '../components/DependentList'
import { chunkFirestoreResults } from '../utils/chunkFirestoreResults'

export const PublicProfile = ({ match: { params } }: { match: { params: { uid: string } } }) => {
  const [items, setItems] = useState<any[]>([])
  const [profile, setProfile] = useState<any>(null)
  const customViewer = useCustomViewer()
  const theme = useTheme()

  useEffect(() => {
    ;(async () => {
      if (customViewer?.uid) {
        const [profileSnapshot, connectionsDocs, dependentLinksDocs] = await Promise.all([
          firestore.collection('users').doc(params.uid).get(),
          firestore.collection(`users/${params.uid}/connections`).get(),
          firestore.collection(`users/${params.uid}/dependentLinks`).get(),
        ])

        let connectionProfileData = []
        let dependentLinkProfileData: any[] = []

        if (connectionsDocs.docs.length) {
          const connectionResults = await chunkFirestoreResults(
            'users',
            'uid',
            connectionsDocs.docs.map((doc) => doc.data())
          )

          connectionProfileData = connectionResults
        }

        if (dependentLinksDocs.docs.length) {
          const dependentLinkProfileResults = await chunkFirestoreResults(
            'users',
            'uid',
            dependentLinksDocs.docs.map((doc) => doc.data())
          )

          const dependentsData: any[] = await Promise.all(
            dependentLinkProfileResults.map((dependentParentProfile) =>
              getDependentsByOwnerUid(customViewer?.uid, dependentParentProfile.uid)
            )
          )

          dependentLinkProfileData = dependentsData
            .reduce((acc, cur) => acc.concat(cur), [])
            .map((dependent: any) => ({
              ...dependent,
              ...dependentLinkProfileResults.find((x) => x.uid === dependent.ownerId),
            }))
        }

        const profileDependents = await getDependentsByOwnerUid(customViewer?.uid, params.uid)

        setProfile({
          ...profileSnapshot.data(),
          connections: connectionProfileData,
          dependentLinks: dependentLinkProfileData,
          dependents: profileDependents,
          allDependents: [...profileDependents, ...dependentLinkProfileData],
        })

        const itemsSnapshot = await firestore
          .collection(`users/${params.uid}/items`)
          .where('visibleToConnections', '==', true)
          .get()

        const newItems: any[] = []

        itemsSnapshot.forEach((doc) => {
          newItems.push({ ...doc.data(), id: doc.id })
        })

        setItems(newItems)
      }
    })()
  }, [params.uid, customViewer?.uid])

  const onRequestConnection = async (connectionId: string) => {
    if (customViewer) {
      requestConnection(customViewer?.uid, connectionId)
    }
  }

  const onAcceptRequest = async (uidToAccept: string) => {
    if (customViewer) {
      acceptRequest(customViewer?.uid, customViewer?.incomingConnectionRequests, uidToAccept)
    }
  }

  const onDenyRequest = async (uidToDeny: string) => {
    if (customViewer) {
      denyRequest(customViewer.uid, customViewer?.incomingConnectionRequests, uidToDeny)
    }
  }

  const onRemoveConnection = async (uidToRemove: string) => {
    if (customViewer) {
      removeConnection(customViewer?.uid, customViewer?.connections, uidToRemove)
    }
  }

  const relationship = (() => {
    if (customViewer?.uid === params.uid) {
      return RELATIONSHIPS.SELF
    } else if (customViewer.outgoingConnectionRequests.some(({ uid }: { uid: string }) => params.uid === uid)) {
      return RELATIONSHIPS.REQUESTED_OUT
    } else if (customViewer.incomingConnectionRequests.some(({ uid }: { uid: string }) => params.uid === uid)) {
      return RELATIONSHIPS.REQUESTED_IN
    } else if (customViewer.connections.some(({ uid }: { uid: string }) => params.uid === uid)) {
      return RELATIONSHIPS.CONNECTED
    } else {
      return RELATIONSHIPS.NONE
    }
  })()

  const includeActionPanel =
    customViewer?.firebaseViewer &&
    [RELATIONSHIPS.REQUESTED_IN, RELATIONSHIPS.CONNECTED, RELATIONSHIPS.NONE].includes(relationship)

  return (
    <>
      <Helmet>
        <title>{profile?.displayName ? `${profile?.displayName} | Sizably` : 'Profile | Sizably'}</title>
      </Helmet>
      <div>
        {!customViewer?.firebaseViewer ? (
          <Card style={{ margin: '30px', padding: '20px' }}>
            <Typography variant={'h4'} color={'primary'} style={{ textAlign: 'center' }}>
              <Link to={'#'} onClick={() => signInWithGoogle()} style={{ color: theme.palette.secondary.main }}>
                Sign In
              </Link>
              {` to view this profile`}
            </Typography>
          </Card>
        ) : (
          <>
            <Typography variant={'h3'} style={{ textAlign: 'center' }} color={'primary'}>
              {profile?.displayName}
            </Typography>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexFlow: 'column nowrap' }}>
              <Avatar src={profile?.photoURL} alt={'Profile'} style={{ width: '100px', height: '100px' }} />
              {profile?.personalBio && (
                <Typography variant={'body1'} style={{ textAlign: 'center', marginTop: '20px' }} color={'primary'}>
                  {profile?.personalBio}
                </Typography>
              )}
              {relationship === RELATIONSHIPS.REQUESTED_OUT && (
                <Card style={{ padding: '20px', margin: '30px' }}>
                  <Typography variant={'h4'} style={{ textAlign: 'center' }} color={'primary'}>
                    Connection Request Sent!
                  </Typography>
                </Card>
              )}
              {includeActionPanel && (
                <div style={{ display: 'flex', flexFlow: 'column nowrap', marginTop: '20px' }}>
                  {relationship === RELATIONSHIPS.REQUESTED_IN && (
                    <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
                      <Button
                        size={'small'}
                        variant={'contained'}
                        color={'primary'}
                        onClick={(e) => {
                          e.preventDefault()
                          onAcceptRequest(params.uid)
                        }}
                        style={{ marginBottom: '8px' }}
                      >
                        Accept Request
                      </Button>
                      <Button
                        size={'small'}
                        variant={'contained'}
                        color={'primary'}
                        onClick={(e) => {
                          e.preventDefault()
                          onDenyRequest(params.uid)
                        }}
                      >
                        Deny Request
                      </Button>
                    </div>
                  )}

                  {relationship === RELATIONSHIPS.CONNECTED && (
                    <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
                      <Button
                        size={'small'}
                        variant={'contained'}
                        color={'primary'}
                        onClick={(e) => {
                          e.preventDefault()
                          onRemoveConnection(params.uid)
                        }}
                      >
                        Disconnect
                      </Button>
                    </div>
                  )}

                  {relationship === RELATIONSHIPS.NONE && (
                    <Button
                      size={'small'}
                      variant={'contained'}
                      color={'primary'}
                      onClick={(e) => {
                        e.preventDefault()
                        onRequestConnection(params.uid)
                      }}
                    >
                      Connect
                    </Button>
                  )}
                </div>
              )}
            </div>
            {(relationship === RELATIONSHIPS.CONNECTED || relationship === RELATIONSHIPS.SELF) && (
              <>
                <SizesList connectionItems={items} />
                <Card style={{ margin: '30px', padding: '20px' }}>
                  <DependentList dependentList={profile?.allDependents} />
                </Card>
                <ProfileConnections connections={profile?.connections} displayName={profile?.displayName} />
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}
