import {
  Card,
  CardActionArea,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  ListItem,
  Typography,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import React from 'react'
import { Link } from 'react-router-dom'

export const DependentListItem = ({
  dependent: { name, bio, id, category, ownerUid, visibleToConnections, items },
  isExternalItem,
}: {
  dependent: {
    name: string
    bio: string
    id: string
    category: string
    ownerUid: string
    visibleToConnections: boolean
    items?: any[]
  }
  isExternalItem?: boolean
}) => {
  return (
    <ListItem style={{ width: '250px', alignSelf: 'flex-start' }}>
      <Card
        style={{
          display: 'flex',
          flexFlow: 'column nowrap',
          padding: '12px',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}>
            <div>
              <Typography variant={'body1'} style={{ fontSize: '1.25rem' }} color={'textPrimary'}>
                {name}
              </Typography>
              <Typography variant={'body1'} color={'textSecondary'}>
                {category}
              </Typography>
            </div>
            {!isExternalItem && (
              <Link to={`/dependent/${id}/owner/${ownerUid}`} style={{ textDecoration: 'none', padding: '12px' }}>
                <Edit color={'primary'} />
              </Link>
            )}
          </div>
          <Divider />
          <div>
            <Typography variant={'body2'} color={'textSecondary'} style={{ marginTop: '8px', fontStyle: 'italic' }}>
              {bio}
            </Typography>
            {!!items?.length && (
              <div style={{ marginTop: '12px' }}>
                <Typography variant={'body1'} style={{ fontSize: '1.1rem' }}>
                  Sizes
                </Typography>
                <Divider />
                {items.map((item) => (
                  <div key={`DependentItemListItem:${item.id}`}>
                    <Typography
                      variant={'body2'}
                      style={{ margin: '8px 0px' }}
                    >{`${item.name} - ${item.size}`}</Typography>
                    <Typography
                      variant={'body2'}
                      color={'textSecondary'}
                      style={{ margin: '0px 0px', fontStyle: 'italic' }}
                    >{`${item.note}`}</Typography>
                  </div>
                ))}
              </div>
            )}
            {!isExternalItem && (
              <FormControl style={{ margin: '12px 0px' }}>
                <FormControlLabel
                  control={<Checkbox checked={visibleToConnections} color={'primary'} />}
                  label={'Visible To Connections'}
                />
              </FormControl>
            )}
          </div>
        </div>
        {!isExternalItem && (
          <>
            <Divider />
            <CardActionArea style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end' }}>
              <Link to={`/dependent/${id}/owner/${ownerUid}`} style={{ textDecoration: 'none' }}>
                <Typography variant={'body1'} color={'secondary'} style={{ marginTop: '6px' }}>
                  Add Sizes
                </Typography>
              </Link>
            </CardActionArea>
          </>
        )}
      </Card>
    </ListItem>
  )
}
