import { List, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { getLinkedDependentsByUid } from '../domain/getLinkedDependentsByUid'
import { useCustomViewer } from '../hooks/useCustomViewer'
import { DependentListItem } from './DependentListItem'
import { HeaderWithBadge } from './HeaderWithBadge'

export const DependentList = ({ dependentList }: { dependentList?: any[] }) => {
  const customViewer = useCustomViewer()
  const [dependentsToShow, setDependentsToShow] = useState<any[]>([])

  useEffect(() => {
    ;(async () => {
      if (dependentList) {
        setDependentsToShow(dependentList)
      } else if (customViewer?.uid) {
        const linkedDependents = await getLinkedDependentsByUid(customViewer?.uid)
        const listOfDependents = dependentList ? dependentList : customViewer?.dependents

        setDependentsToShow([...listOfDependents, ...linkedDependents])
      }
    })()
  }, [customViewer?.uid, customViewer?.dependents, dependentList])

  return (
    <div>
      <HeaderWithBadge text={'Dependents'} count={dependentsToShow?.length} />

      <div style={{ marginBottom: '20px' }}>
        {dependentsToShow?.length ? (
          <List
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              justifyContent: 'center',
            }}
          >
            {dependentsToShow?.map((dependent: any) => (
              <DependentListItem
                dependent={dependent}
                isExternalItem={!!dependentList}
                key={`DependentListItem:${dependent.id}`}
              />
            ))}
          </List>
        ) : (
          <Typography variant={'body1'}>
            {dependentList ? 'No dependents. Yet.' : 'You have no dependents. Yet.'}
          </Typography>
        )}
      </div>
    </div>
  )
}
