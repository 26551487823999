import { Card, Typography } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import React, { useEffect, useState } from 'react'
import { useCustomViewer } from '../hooks/useCustomViewer'
import { firestore } from '../utils/firebase'
import { AddDependentForm } from '../components/AddDependentForm'
import { AddDependentItemForm } from '../components/AddDependentItemForm'
import { DependentItemList } from '../components/DependentItemList'

export const PrivateDependentProfile = ({
  match: {
    params: { dependentId, ownerUid },
  },
}: any) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<null | string>(null)
  const [dependent, setDependent] = useState<any>(null)
  const customViewer = useCustomViewer()

  useEffect(() => {
    const getDependent = () => {
      return firestore
        .collection(`users/${ownerUid}/dependents`)
        .doc(dependentId)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            setDependent({ ...snapshot.data(), id: snapshot.id })
            setLoading(false)
            setError('')
          } else {
            setError(`This dependent does not exist.`)
          }
        })
    }

    if (customViewer?.uid && customViewer?.dependentLinks) {
      if (!dependentId || !ownerUid) {
        setError('Invalid url provided.')
        setLoading(false)
      } else if (customViewer?.uid === ownerUid) {
        setLoading(false)
        return getDependent()
      } else if (customViewer?.dependentLinks) {
        if (!customViewer?.dependentLinks?.some((link: any) => link.uid === ownerUid)) {
          setError('You do not have access to edit this dependent.')
        } else {
          return getDependent()
        }

        setLoading(false)
      }
    } else {
      setError(`You need to be logged in to edit this dependent.`)
    }
  }, [customViewer?.uid, customViewer?.dependentLinks, dependentId, ownerUid])

  return (
    <>
      {loading ? (
        <>
          <Helmet>
            <title>{`Loading | Sizably`}</title>
          </Helmet>
          <Typography variant={'h4'} color={'primary'}>
            Checking Permissions...
          </Typography>
        </>
      ) : error ? (
        <>
          <Helmet>
            <title>{`Error | Sizably`}</title>
          </Helmet>
          <Typography variant={'h4'} color={'error'} style={{ textAlign: 'center' }}>
            {`${error}`}
          </Typography>
        </>
      ) : (
        <>
          <Helmet>
            <title>{`${dependent?.name} Private Profile | Sizably`}</title>
          </Helmet>
          <Card style={{ margin: '30px', padding: '20px', display: 'flex', flexFlow: 'column nowrap' }}>
            <AddDependentForm dependent={dependent} />

            <div style={{ marginTop: '20px' }} />
            <AddDependentItemForm dependent={dependent} />

            <div style={{ margin: '20px 0px' }}>
              <DependentItemList dependent={dependent} />
            </div>
          </Card>
        </>
      )}
    </>
  )
}
