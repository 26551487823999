import React, { useState } from 'react'
import firebase from 'firebase'
import {
  Button,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import { firestore } from '../utils/firebase'
import { useCustomViewer } from '../hooks/useCustomViewer'
import { dependentOptions } from '../utils/Dependents'
import { Delete } from '@material-ui/icons'

export const AddDependentItemForm = ({ dependent, item, onAfterSubmit }: any) => {
  const [editableItem, setEditableItem] = useState(item ?? { category: '', name: '' })
  const [size, setSize] = useState(item?.size ?? '')
  const [note, setNote] = useState(item?.note ?? '')
  const [visibleToConnections, setVisibleToConnections] = useState(item?.visibleToConnections ?? true)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const customViewer = useCustomViewer()
  const theme = useTheme()

  const onSubmit = async () => {
    if (editableItem?.category?.trim() && editableItem?.name?.trim()) {
      const payload = {
        name: editableItem?.name?.trim(),
        category: editableItem?.category?.trim(),
        size: size?.trim(),
        note: note?.trim(),
        visibleToConnections,
        timestamp: firebase.firestore.Timestamp.now(),
      }

      if (item && item?.id) {
        firestore
          .collection(`users/${dependent?.ownerUid}/dependents/${dependent?.id}/items`)
          .doc(item?.id)
          .update(payload)

        if (onAfterSubmit) {
          onAfterSubmit()
        }
      } else {
        firestore.collection(`users/${dependent?.ownerUid}/dependents/${dependent?.id}/items`).add(payload)
        clearForm()
      }
    }
  }

  const onRemoveItem = () => {
    if (item) {
      firestore.collection(`users/${dependent?.ownerUid}/dependents/${dependent?.id}/items`).doc(item.id).delete()

      if (onAfterSubmit) {
        onAfterSubmit()
      }
    }
  }

  const clearForm = () => {
    setEditableItem({ category: '', name: '' })
    setSize('')
    setNote('')
    setVisibleToConnections(true)
  }

  const formDirty = item
    ? item?.name?.trim() !== editableItem?.name?.trim() ||
      item?.size?.trim() !== size?.trim() ||
      item?.note?.trim() !== note?.trim() ||
      item?.visibleToConnections !== visibleToConnections
    : editableItem?.category?.trim()?.length ||
      editableItem?.name?.trim()?.length ||
      note?.trim()?.length ||
      !visibleToConnections

  const dependentOption = dependentOptions.find((option) => option.name === dependent?.category)

  return (
    <>
      {customViewer?.firebaseViewer && (
        <>
          <Typography variant={'h4'} color={'primary'}>
            {!!item
              ? `Editing ${item.name} for ${dependent?.name}`
              : dependent
              ? `Add New Item For ${dependent?.name}`
              : `Add New Dependent Item`}
          </Typography>
          <Divider style={{ marginBottom: '20px' }} />
          <FormControl>
            <InputLabel htmlFor={'dependent-item-select'}>Item</InputLabel>
            <Select id={'dependent-item-select'} value={editableItem.name} style={{ textAlign: 'left' }}>
              {dependentOption?.itemOptions.map((item) => (
                <MenuItem
                  value={item.name}
                  style={{ marginLeft: '20px' }}
                  key={`ItemName-${item.name}`}
                  onClick={() => setEditableItem(item)}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField value={size} onChange={(e) => setSize(e.target.value)} label={'Size'} />
          <TextField value={note} onChange={(e) => setNote(e.target.value)} label={'Note'} />
          <FormControl style={{ marginTop: '20px' }}>
            <FormLabel component={'legend'} color={'secondary'}>
              Item Privacy
            </FormLabel>
            <RadioGroup
              value={visibleToConnections}
              onChange={() => setVisibleToConnections(!visibleToConnections)}
              style={{ display: 'flex', flexFlow: 'row wrap' }}
            >
              <FormControlLabel value={true} control={<Radio />} label={'Connections Only'} />
              <FormControlLabel value={false} control={<Radio />} label={'Private'} />
            </RadioGroup>
            {!visibleToConnections && (
              <FormHelperText>
                Users linked to your dependents will still see, and have access to edit this item.
              </FormHelperText>
            )}
          </FormControl>
          {(!!item || formDirty) && (
            <>
              <Divider style={{ margin: '20px 0px' }} />
              <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {!!item && (
                  <IconButton style={{ color: theme.palette.error.main }} onClick={() => setDeleteDialogOpen(true)}>
                    <Delete />
                  </IconButton>
                )}
                {!!item && formDirty && (
                  <Button size={'large'} color={'secondary'} variant={'contained'} onClick={onSubmit}>
                    Save
                  </Button>
                )}

                {!item && formDirty && (
                  <Button size={'large'} color={'secondary'} variant={'contained'} onClick={onSubmit}>
                    Add
                  </Button>
                )}
              </CardActions>
            </>
          )}
        </>
      )}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} fullWidth>
        <DialogTitle>{`Are you sure you want to remove ${editableItem.name}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>This action is non-reversible.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={'secondary'}
            variant={'contained'}
            onClick={() => setDeleteDialogOpen(false)}
          >{`Cancel`}</Button>
          <Button
            color={'primary'}
            variant={'contained'}
            onClick={onRemoveItem}
          >{`Remove ${editableItem.name}`}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
