import chunk from 'lodash.chunk'
import { firestore } from './firebase'

export const chunkFirestoreResults = async (
  collectionName: string,
  compareField: string,
  listOfValues: any[]
): Promise<any[]> => {
  const chunked = chunk(listOfValues, 10)
  const chunkedResultDocs = await Promise.all(
    chunked.map((chunk) =>
      firestore
        .collection(collectionName)
        .where(
          compareField,
          'in',
          chunk.map((chunkItem) => chunkItem[compareField])
        )
        .get()
    )
  )

  return chunkedResultDocs
    .map((x) => x.docs.map((y) => ({ ...y.data(), id: y.id })))
    .reduce((acc, cur) => acc.concat(cur), [])
}
