import { List, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { firestore } from '../utils/firebase'
import { DependentItemListItem } from './DependentItemListItem'
import { HeaderWithBadge } from './HeaderWithBadge'

export const DependentItemList = ({ dependent }: any) => {
  const [dependentItems, setDependentItems] = useState<any[]>([])

  useEffect(() => {
    const unsubscribeFromDependentItems = firestore
      .collection(`users/${dependent?.ownerUid}/dependents/${dependent?.id}/items`)
      .onSnapshot((snapshot) => {
        setDependentItems(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      })

    return unsubscribeFromDependentItems
  }, [dependent?.id, dependent?.ownerUid])

  return (
    <div>
      <HeaderWithBadge text={`${dependent?.name}'s Sizes`} count={dependentItems?.length} />

      <div style={{ marginBottom: '20px' }}>
        {!!dependentItems.length ? (
          <List
            style={{
              display: 'flex',
              flexFlow: 'row wrap',
              justifyContent: 'center',
            }}
          >
            {dependentItems.map((item: any) => (
              <DependentItemListItem item={item} dependent={dependent} isExternalItem={false} />
            ))}
          </List>
        ) : (
          <Typography variant={'body1'}>{`${dependent?.name} has no items. Yet.`}</Typography>
        )}
      </div>
    </div>
  )
}
