import { accessories, apparel, body, cats, dogs, horses, shoes } from './Items'

export enum DEPENDENT_CATEGORIES {
  HUMANS = 'Humans',
  PETS = 'Pets',
}

export const dependentOptions = [
  {
    name: 'Child',
    category: DEPENDENT_CATEGORIES.HUMANS,
    itemOptions: [...accessories, ...apparel, ...body, ...shoes],
  },
  {
    name: 'Adult',
    category: DEPENDENT_CATEGORIES.HUMANS,
    itemOptions: [...accessories, ...apparel, ...body, ...shoes],
  },
  {
    name: 'Cat',
    category: DEPENDENT_CATEGORIES.PETS,
    itemOptions: [...cats],
  },
  {
    name: 'Dog',
    category: DEPENDENT_CATEGORIES.PETS,
    itemOptions: [...dogs],
  },
  {
    name: 'Horse',
    category: DEPENDENT_CATEGORIES.PETS,
    itemOptions: [...horses],
  },
]
