import { firestore } from '../utils/firebase'

export const requestConnection = async (viewerUid: string, connectionId: string) => {
  Promise.all([
    firestore.collection(`users`).doc(viewerUid).collection('outgoingConnectionRequests').add({ uid: connectionId }),
    firestore
      .collection(`users`)
      .doc(connectionId)
      .collection('incomingConnectionRequests')
      .add({ uid: viewerUid, ignored: false }),
  ])
}

export const acceptRequest = async (viewerUid: string, viewerIncomingRequests: any[], uidToAccept: string) => {
  const incomingRequest = viewerIncomingRequests.find((req: any) => req.uid === uidToAccept)
  const outgoingRequestSnapshot = await firestore
    .collection(`users/${uidToAccept}/outgoingConnectionRequests`)
    .where('uid', '==', viewerUid)
    .get()

  const outgoingRequestDoc = outgoingRequestSnapshot.docs.find((x) => !!x)
  const outgoingRequest = { ...outgoingRequestDoc?.data(), id: outgoingRequestDoc?.id }

  const promises: any[] = [
    firestore.collection(`users/${viewerUid}/connections`).add({ uid: uidToAccept }),
    firestore.collection(`users/${uidToAccept}/connections`).add({ uid: viewerUid }),
    firestore.collection(`users/${viewerUid}/incomingConnectionRequests`).doc(incomingRequest.id).delete(),
  ]

  if (outgoingRequest) {
    promises.push(
      firestore.collection(`users/${uidToAccept}/outgoingConnectionRequests`).doc(outgoingRequest.id).delete()
    )
  }

  await Promise.all(promises)
}

export const denyRequest = async (viewerUid: string, viewerIncomingRequests: any[], uidToDeny: string) => {
  const incomingRequest = viewerIncomingRequests.find((req: any) => req.uid === uidToDeny)
  const outgoingRequestSnapshot = await firestore
    .collection(`users/${uidToDeny}/outgoingConnectionRequests`)
    .where('uid', '==', viewerUid)
    .get()

  const outgoingRequestDoc = outgoingRequestSnapshot.docs.find((x) => !!x)
  const outgoingRequest = { ...outgoingRequestDoc?.data(), id: outgoingRequestDoc?.id }

  await Promise.all([
    firestore.collection(`users/${viewerUid}/incomingConnectionRequests`).doc(incomingRequest.id).delete(),
    firestore.collection(`users/${uidToDeny}/outgoingConnectionRequests`).doc(outgoingRequest.id).delete(),
  ])
}

export const removeConnection = async (viewerUid: string, viewerConnections: any[], uidToRemove: string) => {
  const myConnection = viewerConnections.find((req: any) => req.uid === uidToRemove)
  const theirConnectionSnapshot = await firestore
    .collection(`users/${uidToRemove}/connections`)
    .where('uid', '==', viewerUid)
    .get()
  const theirConnectionDoc = theirConnectionSnapshot.docs.find((x) => !!x)
  const theirConnection = { ...theirConnectionDoc?.data(), id: theirConnectionDoc?.id }

  await Promise.all([
    firestore.collection(`users/${viewerUid}/connections`).doc(myConnection.id).delete(),
    firestore.collection(`users/${uidToRemove}/connections`).doc(theirConnection.id).delete(),
  ])
}
