import React, { useState, useEffect } from 'react'
import { Card, List, Typography } from '@material-ui/core'
import { useCustomViewer } from '../../hooks/useCustomViewer'
import { RequestListItem } from '../RequestListItem'
import { HeaderWithBadge } from '../HeaderWithBadge'
import { SEARCH_TYPES } from '../SearchListItem'
import { chunkFirestoreResults } from '../../utils/chunkFirestoreResults'

export const IncomingRequests = () => {
  const [incomingConnectionRequests, setIncomingConnectionRequests] = useState<any[]>([])
  const [incomingDependentLinkRequests, setIncomingDependentLinkRequests] = useState<any[]>([])
  const customViewer = useCustomViewer()

  useEffect(() => {
    ;(async () => {
      if (customViewer?.incomingDependentLinkRequests?.length) {
        const dependentResults = await chunkFirestoreResults(
          'users',
          'uid',
          customViewer?.incomingDependentLinkRequests
        )

        setIncomingDependentLinkRequests(dependentResults)
      } else {
        setIncomingDependentLinkRequests([])
      }
    })()
  }, [customViewer?.incomingDependentLinkRequests])

  useEffect(() => {
    ;(async () => {
      if (customViewer?.incomingConnectionRequests?.length) {
        const connectionResults = await chunkFirestoreResults('users', 'uid', customViewer?.incomingConnectionRequests)

        setIncomingConnectionRequests(connectionResults)
      } else {
        setIncomingConnectionRequests([])
      }
    })()
  }, [customViewer?.incomingConnectionRequests])

  return (
    <>
      {customViewer?.firebaseViewer && (
        <Card
          style={{
            maxWidth: '100vw',
            margin: '30px',
            display: 'flex',
            flexFlow: 'column nowrap',
            padding: '20px',
          }}
        >
          <HeaderWithBadge text={'Connection Requests'} count={incomingConnectionRequests?.length} />
          {incomingConnectionRequests.length === 0 ? (
            <Typography variant={'body2'}>You have no connection requests. Yet.</Typography>
          ) : (
            <>
              <List style={{ display: 'flex', flexFlow: 'row wrap' }}>
                {incomingConnectionRequests.map((incomingRequest) => (
                  <RequestListItem
                    user={incomingRequest}
                    key={`IncomingRequest-${incomingRequest.uid}`}
                    type={SEARCH_TYPES.CONNECTIONS}
                  />
                ))}
              </List>
            </>
          )}

          <div style={{ margin: '20px 0px' }} />

          <HeaderWithBadge text={'Dependent Link Requests'} count={incomingDependentLinkRequests?.length} />
          {incomingDependentLinkRequests?.length === 0 ? (
            <Typography variant={'body2'}>You have no dependent link requests. Yet.</Typography>
          ) : (
            <List style={{ display: 'flex', flexFlow: 'row wrap' }}>
              {incomingDependentLinkRequests.map((incomingRequest) => (
                <RequestListItem
                  user={incomingRequest}
                  key={`IncomingRequest-${incomingRequest.uid}`}
                  type={SEARCH_TYPES.DEPENDENT_LINK}
                />
              ))}
            </List>
          )}
        </Card>
      )}
    </>
  )
}
