import React from 'react'
import { Card, List, Typography } from '@material-ui/core'
import { HeaderWithBadge } from './HeaderWithBadge'
import { RectangularUserListItem } from './RectangularUserListItem'

export const ProfileConnections = ({ displayName, connections }: { displayName: string; connections: any[] }) => {
  return (
    <>
      <Card
        style={{
          margin: '30px',
          display: 'flex',
          flexFlow: 'column nowrap',
          padding: '20px',
        }}
      >
        <HeaderWithBadge text={'Connections'} count={connections?.length} />
        {connections?.length === 0 ? (
          <Typography variant={'body2'}>{displayName} has no connections. Yet.</Typography>
        ) : (
          <List style={{ display: 'flex', flexFlow: 'row wrap', alignItems: 'flex-start', justifyContent: 'center' }}>
            {connections?.map((connection) => (
              <RectangularUserListItem user={connection} key={`Connection-${connection.uid}`} />
            ))}
          </List>
        )}
      </Card>
    </>
  )
}
