import React, { useState } from 'react'
import { List, TextField } from '@material-ui/core'
import { firestore } from '../utils/firebase'
import { SearchListItem, SEARCH_TYPES } from './SearchListItem'
import { SearchOutlined } from '@material-ui/icons'

export const Search = ({ searchType }: { searchType: SEARCH_TYPES }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchHits, setSearchHits] = useState<any[]>([])

  const getSearchResults = async (paramSearchTerm?: string) => {
    if (paramSearchTerm?.trim().length) {
      const newHits: any[] = []
      setSearchHits([])
      const snapshot = await firestore.collection('users').where('searchable', '==', true).get()

      snapshot.forEach((doc) => {
        const data = doc.data()

        if (
          data?.displayName?.toLowerCase()?.includes((paramSearchTerm || searchTerm).toLowerCase()) ||
          data?.email?.toLowerCase()?.includes((paramSearchTerm || searchTerm).toLowerCase())
        ) {
          newHits.push({ ...data, id: doc.id })
        }
      })

      setSearchHits(newHits)
    } else {
      setSearchHits([])
    }
  }

  return (
    <>
      <TextField
        value={searchTerm}
        onChange={async (e) => {
          setSearchTerm(e.target.value)
          await getSearchResults(e.target.value)
        }}
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SearchOutlined />
            <span>Search For People</span>
          </div>
        }
      />
      {searchHits.length ? (
        <List>
          {searchHits.map((hit) => (
            <SearchListItem user={hit} key={`UserListItem-${hit.uid}`} searchType={searchType} />
          ))}
        </List>
      ) : null}
    </>
  )
}
