import React from 'react'

export const RectangleProfileImage = ({ user }: { user: any }) => {
  return (
    <img
      alt={`${user.displayName} Profile`}
      src={user.photoURL}
      style={{ width: '100%', height: '150px', borderRadius: '5px' }}
    />
  )
}
