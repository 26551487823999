import { Button, Card, FormControl, TextareaAutosize, TextField, Typography } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import React, { useEffect, useState } from 'react'
import { useFirebaseViewer } from '../hooks/useFirebaseViewer'
import { firestore } from '../utils/firebase'

export const FeedbackForm = () => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [feedbackBody, setFeedbackBody] = useState<string>('')
  const firebaseViewer = useFirebaseViewer()
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (firebaseViewer?.displayName) {
      setName(firebaseViewer.displayName)
    }
    if (firebaseViewer?.email) {
      setEmail(firebaseViewer.email)
    }
  }, [firebaseViewer?.displayName, firebaseViewer?.email])

  const onSubmit = async (e: any) => {
    e.preventDefault()
    if (name.trim() && email.trim() && feedbackBody.trim()) {
      await firestore.collection('feedback').add({
        name: name.trim(),
        email: email.trim(),
        feedback: feedbackBody.trim(),
      })

      setName(firebaseViewer?.displayName || '')
      setEmail(firebaseViewer?.email || '')
      setFeedbackBody('')
      setMessage('Feedback successfully submitted. Thank you!')

      setTimeout(() => {
        setMessage('')
      }, 5000)
    }
  }
  return (
    <>
      <Helmet>
        <title>Send Feedback | Sizably</title>
      </Helmet>
      <Card style={{ padding: '20px', margin: '30px' }}>
        <Typography variant={'h3'} style={{ textAlign: 'center' }} color={'secondary'}>
          Leave Feedback
        </Typography>
        <form name='feedback' style={{ display: 'flex', flexFlow: 'column nowrap' }} onSubmit={onSubmit}>
          <input type='hidden' name='form-name' value='contact' />
          <TextField
            id={'name'}
            name={'name'}
            value={name}
            onChange={(e) => setName(e.target.value)}
            label={'Name'}
            style={{ marginBottom: '12px' }}
          />
          <TextField
            id={'email'}
            name={'email'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label={'Email'}
            style={{ marginBottom: '12px' }}
          />
          <FormControl>
            <Typography variant={'body1'} color={'textSecondary'}>
              Feedback
            </Typography>
            <TextareaAutosize
              id={'feedback'}
              name={'feedback'}
              value={feedbackBody}
              onChange={(e) => setFeedbackBody(e.target.value)}
              style={{ marginBottom: '12px', width: '100%' }}
              rowsMin={10}
            />
          </FormControl>
          <Button type={'submit'} variant={'contained'} color={'secondary'}>
            Submit
          </Button>
        </form>
        {message && (
          <Typography variant={'body1'} color={'secondary'} style={{ marginTop: '20px' }}>
            {message}
          </Typography>
        )}
      </Card>
    </>
  )
}
