import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardActions,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextareaAutosize,
  TextField,
  Typography,
} from '@material-ui/core'
import { useCustomViewer } from '../../hooks/useCustomViewer'
import { firestore } from '../../utils/firebase'

export const Profile = () => {
  const [displayName, setDisplayName] = useState('')
  const [searchable, setSearchable] = useState(true)
  const [personalBio, setPersonalBio] = useState('')
  const customViewer = useCustomViewer()

  useEffect(() => {
    ;(async () => {
      if (customViewer?.displayName !== undefined) {
        setDisplayName(customViewer?.displayName)
      }
      if (customViewer?.searchable !== undefined) {
        setSearchable(customViewer?.searchable)
      }
      if (customViewer?.personalBio !== undefined) {
        setPersonalBio(customViewer?.personalBio)
      }
    })()
  }, [customViewer?.displayName, customViewer?.searchable, customViewer?.personalBio])

  const saveEnabled =
    customViewer &&
    (customViewer?.displayName !== displayName ||
      customViewer?.searchable !== searchable ||
      customViewer?.personalBio !== personalBio)

  const saveProfile = async () => {
    await firestore
      .collection(`users`)
      .doc(customViewer.firebaseViewer?.uid)
      .update({ searchable, displayName: displayName.trim(), personalBio: personalBio.trim() })
  }

  return (
    <>
      {customViewer?.firebaseViewer ? (
        <Card style={{ display: 'flex', flexFlow: 'column nowrap', padding: '20px', margin: '30px' }}>
          <Typography variant={'h4'} color={'primary'}>
            My Profile
          </Typography>
          <Divider style={{ marginBottom: '20px' }} />
          <TextField
            label={'Display Name'}
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            style={{ marginBottom: '20px' }}
          />
          <FormControl style={{ marginBottom: '20px' }}>
            <FormControlLabel
              control={<Checkbox checked={searchable} onClick={() => setSearchable(!searchable)} color={'primary'} />}
              label='Searchable'
            />
            <FormHelperText>
              {searchable
                ? 'You will be visible to others in their searches'
                : `You will never be visible to other user's in their searches`}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <Typography variant={'body1'} color={'textSecondary'}>
              Personal Bio
            </Typography>
            <TextareaAutosize
              value={personalBio}
              onChange={(e) => setPersonalBio(e.target.value)}
              style={{ marginBottom: '12px', width: '100%', padding: '12px' }}
              rowsMin={5}
              required={true}
            />
          </FormControl>
          <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {saveEnabled ? (
              <Button onClick={saveProfile} variant={'contained'} color={'secondary'}>
                Save
              </Button>
            ) : null}
          </CardActions>
        </Card>
      ) : null}
    </>
  )
}
