import { useEffect, useState } from 'react'
import firebase from 'firebase'

import { auth } from '../utils/firebase'

export const useFirebaseViewer = () => {
  const [viewer, setViewer] = useState<firebase.User | null>(null)

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      setViewer(userAuth)
    })
  }, [])

  return viewer
}
