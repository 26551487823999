import { useMemo, useState } from 'react'
import firebase from 'firebase'

import { firestore } from '../utils/firebase'
import { useFirebaseViewer } from './useFirebaseViewer'

const getArrayFromCollectionSnapshot = (
  snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  const items: any = []

  snapshot.forEach((document) => {
    items.push({ ...document.data(), id: document.id })
  })

  return items
}

export const useCustomViewer = () => {
  const [customViewer, setCustomViewer] = useState<any>(null)
  const [outgoingConnectionRequests, setOutgoingConnectionRequests] = useState<any[]>([])
  const [incomingConnectionRequests, setIncomingConnectionRequests] = useState<any[]>([])
  const [connections, setConnections] = useState<any[]>([])
  const [outgoingDependentLinkRequests, setOutgoingDependentLinkRequests] = useState<any[]>([])
  const [incomingDependentLinkRequests, setIncomingDependentLinkRequests] = useState<any[]>([])
  const [dependentLinks, setDependentLinks] = useState<any[]>([])
  const [dependents, setDependents] = useState<any[]>([])
  const firebaseViewer = useFirebaseViewer()

  useMemo(() => {
    if (firebaseViewer) {
      const unsubscribeOutgoingRequests = firestore
        .collection(`users/${firebaseViewer.uid}/outgoingConnectionRequests`)
        .onSnapshot(
          (snapshot) => {
            setOutgoingConnectionRequests(getArrayFromCollectionSnapshot(snapshot))
          },
          (err) => {
            console.log('Encountered error:', err)
          }
        )

      const unsubscribeIncomingRequests = firestore
        .collection(`users/${firebaseViewer.uid}/incomingConnectionRequests`)
        .onSnapshot(
          (snapshot) => {
            setIncomingConnectionRequests(getArrayFromCollectionSnapshot(snapshot))
          },
          (err) => {
            console.log('Encountered error:', err)
          }
        )

      const unsubscribeConnections = firestore.collection(`users/${firebaseViewer.uid}/connections`).onSnapshot(
        (snapshot) => {
          setConnections(getArrayFromCollectionSnapshot(snapshot))
        },
        (err) => {
          console.log('Encountered error:', err)
        }
      )

      const unsubscribeDependents = firestore.collection(`users/${firebaseViewer.uid}/dependents`).onSnapshot(
        (snapshot) => {
          setDependents(getArrayFromCollectionSnapshot(snapshot))
        },
        (err) => {
          console.log('Encountered error:', err)
        }
      )

      const unsubscribeOutgoingDependentLinkRequests = firestore
        .collection(`users/${firebaseViewer.uid}/outgoingDependentLinkRequests`)
        .onSnapshot(
          (snapshot) => {
            setOutgoingDependentLinkRequests(getArrayFromCollectionSnapshot(snapshot))
          },
          (err) => {
            console.log('Encountered error:', err)
          }
        )

      const unsubscribeIncomingDependentLinkRequests = firestore
        .collection(`users/${firebaseViewer.uid}/incomingDependentLinkRequests`)
        .onSnapshot(
          (snapshot) => {
            setIncomingDependentLinkRequests(getArrayFromCollectionSnapshot(snapshot))
          },
          (err) => {
            console.log('Encountered error:', err)
          }
        )

      const unsubscribeDependentLinks = firestore.collection(`users/${firebaseViewer.uid}/dependentLinks`).onSnapshot(
        (snapshot) => {
          setDependentLinks(getArrayFromCollectionSnapshot(snapshot))
        },
        (err) => {
          console.log('Encountered error:', err)
        }
      )

      const unsubscribeViewer = firestore.doc(`users/${firebaseViewer.uid}`).onSnapshot(
        (snapshot) => {
          setCustomViewer(snapshot.data())
        },
        (err) => {
          console.log('Encountered error:', err)
        }
      )

      return () => {
        unsubscribeOutgoingRequests()
        unsubscribeIncomingRequests()
        unsubscribeConnections()
        unsubscribeDependents()
        unsubscribeOutgoingDependentLinkRequests()
        unsubscribeIncomingDependentLinkRequests()
        unsubscribeDependentLinks()
        unsubscribeViewer()
      }
    } else {
      setOutgoingConnectionRequests([])
      setIncomingConnectionRequests([])
      setConnections([])
      setOutgoingDependentLinkRequests([])
      setIncomingDependentLinkRequests([])
      setDependentLinks([])
      setDependents([])
      setCustomViewer(null)
    }
  }, [firebaseViewer])

  return {
    outgoingConnectionRequests,
    incomingConnectionRequests,
    connections,
    dependents,
    outgoingDependentLinkRequests,
    incomingDependentLinkRequests,
    dependentLinks,
    ...customViewer,
    firebaseViewer,
  }
}
