import { Badge, Divider, Typography } from '@material-ui/core'
import React from 'react'

export const HeaderWithBadge = ({ text, count, subText }: { text: string; count: number; subText?: string }) => {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <Badge badgeContent={count} color={'error'} style={{ flex: 1 }}>
          <Typography variant={'h4'} color={'primary'}>
            {text}
          </Typography>
        </Badge>
      </div>
      {!!subText && (
        <Typography variant={'body1'} color={'textSecondary'}>
          {subText}
        </Typography>
      )}
      <Divider style={{ marginBottom: '20px' }} />
    </>
  )
}
