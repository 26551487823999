import { Card, Divider, Typography, useTheme } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import React from 'react'

export const NotFound = () => {
  const theme = useTheme()
  return (
    <>
      <Helmet>
        <title>Page Not Found | Sizably</title>
      </Helmet>
      <Card style={{ margin: '30px', padding: '20px' }}>
        <Typography variant={'h4'} color={'primary'}>
          This resource was unable to be located
        </Typography>
        <Divider style={{ marginBottom: '20px' }} />
        <Typography variant={'body1'}>
          Return{' '}
          <Link to={'/'} style={{ color: theme.palette.secondary.main }}>
            Home
          </Link>
        </Typography>
      </Card>
    </>
  )
}
