import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { useTheme } from '@material-ui/core'

import { Dashboard } from './screens/Dashboard'
import { PublicProfile } from './screens/PublicProfile'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { FeedbackForm } from './components/FeedbackForm'
import { FeedbackSuccess } from './screens/FeedbackSuccess'
import { HomeScreen } from './screens/Home'
import { NotFound } from './screens/NotFound'
import { PrivateDependentProfile } from './screens/PrivateDependentProfile'

export const Navigation = () => {
  const theme = useTheme()

  return (
    <div style={{ backgroundColor: theme.palette.secondary.light }}>
      <BrowserRouter>
        <Header />
        <div style={{ padding: '20px 0px' }}>
          <Switch>
            <Route path='/publicProfile/:uid' component={PublicProfile} />
            <Route path='/leaveFeedback' component={FeedbackForm} />
            <Route path='/feedbackSuccess' component={FeedbackSuccess} />
            <Route path='/dependent/:dependentId/owner/:ownerUid' component={PrivateDependentProfile} exact />
            <Route path='/dashboard' component={Dashboard} exact />
            <Route path='/home' component={HomeScreen} exact />
            <Route path='/' component={HomeScreen} exact />
            <Route path='/' component={NotFound} />
          </Switch>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  )
}
