// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { Card, List, Typography } from '@material-ui/core'
import { useCustomViewer } from '../../hooks/useCustomViewer'
import { RectangularUserListItem } from '../RectangularUserListItem'
import { HeaderWithBadge } from '../HeaderWithBadge'
import { chunkFirestoreResults } from '../../utils/chunkFirestoreResults'

export const Connections = () => {
  const [connections, setConnections] = useState<any[]>([])
  const [dependentLinks, setDependentLinks] = useState<any[]>([])
  const customViewer = useCustomViewer()

  useEffect(() => {
    ;(async () => {
      if (customViewer?.connections?.length) {
        const connectionResults = await chunkFirestoreResults('users', 'uid', customViewer?.connections)

        setConnections(connectionResults)
      } else {
        setConnections([])
      }
    })()
  }, [customViewer.connections])

  useEffect(() => {
    ;(async () => {
      if (customViewer?.dependentLinks?.length) {
        const dependentResults = await chunkFirestoreResults('users', 'uid', customViewer?.dependentLinks)

        setDependentLinks(dependentResults)
      } else {
        setDependentLinks([])
      }
    })()
  }, [customViewer.dependentLinks])

  return (
    <>
      {customViewer?.firebaseViewer && (
        <Card
          style={{
            maxWidth: '100vw',
            margin: '30px',
            display: 'flex',
            flexFlow: 'column nowrap',
            padding: '20px',
          }}
        >
          <HeaderWithBadge text={'Connections'} count={connections?.length} />
          {connections?.length === 0 ? (
            <Typography variant={'body2'}>You have no connections. Yet.</Typography>
          ) : (
            <List style={{ display: 'flex', flexFlow: 'row wrap', alignItems: 'flex-start', justifyContent: 'center' }}>
              {connections?.map((connection) => (
                <RectangularUserListItem user={connection} key={`Connection-${connection.uid}`} />
              ))}
            </List>
          )}

          <div style={{ margin: '20px 0px' }} />

          <HeaderWithBadge text={'Dependent Links'} count={dependentLinks?.length} />
          {dependentLinks?.length === 0 ? (
            <Typography variant={'body2'}>You have no dependent links. Yet.</Typography>
          ) : (
            <List style={{ display: 'flex', flexFlow: 'row wrap', alignItems: 'flex-start', justifyContent: 'center' }}>
              {dependentLinks?.map((dependentLink) => (
                <RectangularUserListItem user={dependentLink} key={`dependent-link-${dependentLink.uid}`} />
              ))}
            </List>
          )}
        </Card>
      )}
    </>
  )
}
