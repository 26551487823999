import React, { useEffect, useState } from 'react'
import { Card, Divider, List, Typography } from '@material-ui/core'
import { useFirebaseViewer } from '../../hooks/useFirebaseViewer'
import { firestore } from '../../utils/firebase'
import { SizeListItem } from '../SizeListItem'
import { HeaderWithBadge } from '../HeaderWithBadge'
import { ITEM_CATEGORIES } from '../../utils/Items'

export const SizesList = ({ connectionItems }: { connectionItems?: any[] }) => {
  const [sizes, setSizes] = useState<any[]>([])
  const firebaseViewer = useFirebaseViewer()

  useEffect(() => {
    ;(async () => {
      if (!connectionItems && firebaseViewer) {
        const itemsCollection = firestore.collection(`users/${firebaseViewer?.uid}/items`)
        const unsubscribe = itemsCollection.onSnapshot(
          (itemsSnapshot) => {
            const items: any = []

            itemsSnapshot.forEach((snapshot) => {
              items.push({ ...snapshot.data(), id: snapshot.id })
            })

            setSizes(items)
          },
          (err) => {
            console.log('Encountered error:', err)
          }
        )

        return unsubscribe
      }
    })()
  }, [firebaseViewer, connectionItems])

  const itemsToList = connectionItems ? connectionItems : sizes
  const categoriesInList = Object.values(ITEM_CATEGORIES).filter((category) =>
    itemsToList.some((item) => item.category === category)
  )

  return (
    <>
      {firebaseViewer && (
        <Card
          style={{
            display: 'flex',
            flexFlow: 'column nowrap',
            padding: '20px',
            margin: '30px',
          }}
        >
          <>
            <HeaderWithBadge text={'My Sizes'} count={connectionItems ? connectionItems?.length : sizes?.length} />
            {categoriesInList.length ? (
              <>
                {categoriesInList?.map((category) => {
                  return (
                    <div key={`SizeListCat${category}`}>
                      <Typography variant={'body1'} style={{ textAlign: 'center', marginTop: '12px' }}>
                        {category}
                      </Typography>
                      <List
                        style={{
                          display: 'flex',
                          flexFlow: 'row wrap',
                          justifyContent: 'center',
                        }}
                      >
                        {itemsToList
                          .filter((item) => item?.category === category)
                          .map((s: any) => (
                            <SizeListItem item={s} key={`SizeListItem:${s.id}`} isExternalItem={!!connectionItems} />
                          ))}
                      </List>
                      <Divider />
                    </div>
                  )
                })}
              </>
            ) : (
              <Typography variant={'body2'}>No sizes. Yet.</Typography>
            )}
          </>
        </Card>
      )}
    </>
  )
}
