import React, { useState } from 'react'
import {
  Button,
  Card,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import { useFirebaseViewer } from '../../hooks/useFirebaseViewer'
import { firestore } from '../../utils/firebase'
import { accessories, apparel, body, shoes, ITEM_CATEGORIES } from '../../utils/Items'
import { useHistory } from 'react-router-dom'
import { Delete } from '@material-ui/icons'

export const AddItemCard = ({ item, onSubmitFinished }: any) => {
  const [editableItem, setEditableItem] = useState({ category: item?.category || '', name: item?.name || '' })
  const [size, setSize] = useState(item?.size || '')
  const [note, setNote] = useState(item?.note || '')
  const [visibleToConnections, setVisibleToConnections] = useState(item?.visibleToConnections ?? true)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const firebaseViewer = useFirebaseViewer()
  const history = useHistory()
  const theme = useTheme()
  const itemOptions = [...accessories, ...apparel, ...body, ...shoes]

  const onSubmit = async () => {
    if (!item) {
      if (
        editableItem?.category?.trim() &&
        editableItem?.name.trim() &&
        size.trim() &&
        itemOptions.some((item) => item.name === editableItem?.name.trim())
      ) {
        await firestore.collection(`users/${firebaseViewer?.uid}/items`).add({
          category: editableItem.category.trim(),
          name: editableItem.name.trim(),
          size: size.trim(),
          note: note.trim(),
          visibleToConnections,
        })

        clearForm()
      }
    } else {
      if (!item.id) {
        history.push('/')
      } else {
        await firestore.collection(`users/${firebaseViewer?.uid}/items`).doc(item.id).set({
          category: editableItem.category.trim(),
          name: editableItem.name.trim(),
          size: size.trim(),
          note: note.trim(),
          visibleToConnections,
        })

        onSubmitFinished ? onSubmitFinished() : clearForm()
      }
    }
  }

  const onRemoveItem = async () => {
    if (!!item && firebaseViewer) {
      await firestore.collection(`users/${firebaseViewer?.uid}/items`).doc(item.id).delete()
      onSubmitFinished()
    }
  }

  const clearForm = () => {
    setEditableItem({ name: '', category: '' })
    setSize('')
    setNote('')
    setVisibleToConnections(true)
  }

  const formDirty = !!item
    ? editableItem?.name.trim() !== item.name ||
      size.trim() !== item.size ||
      note.trim() !== item.note ||
      visibleToConnections !== item.visibleToConnections
    : editableItem?.name.trim().length || size.trim().length || note.trim().length || !visibleToConnections

  return (
    <>
      {firebaseViewer && (
        <>
          <Card
            style={{
              display: 'flex',
              flexFlow: 'column nowrap',
              padding: '20px',
              margin: '30px',
            }}
          >
            <>
              <Typography variant={'h4'} color={'primary'}>
                {!!item ? `Editing ${item.name}` : `Add New Item`}
              </Typography>
              <Divider style={{ marginBottom: '20px' }} />
              {!item && (
                <Typography variant={'body1'} style={{ textAlign: 'left' }} color={'textSecondary'}>
                  {`The purpose of this is to add things that you wear or have specific sizes for. Others can then connect with you on Sizably and view these at any time. Perfect for when trying to find gifts for your friends and family!`}
                </Typography>
              )}
              <FormControl style={{ marginTop: '20px' }}>
                <InputLabel htmlFor={'grouped-select'}>Item Name</InputLabel>
                <Select id={'grouped-select'} value={editableItem.name} style={{ textAlign: 'left' }}>
                  {Object.keys(ITEM_CATEGORIES).map((category) => {
                    const categoryDisplayName = Object(ITEM_CATEGORIES)[category]
                    return (
                      <div key={`ItemCategory-${categoryDisplayName}`}>
                        <ListSubheader>{categoryDisplayName}</ListSubheader>
                        {itemOptions
                          .filter((item) => item.category === categoryDisplayName)
                          .map((item) => (
                            <MenuItem
                              value={item.name}
                              style={{ marginLeft: '20px' }}
                              key={`ItemName-${item.name}`}
                              onClick={() => setEditableItem(item)}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                      </div>
                    )
                  })}
                  {itemOptions.map((item) => (
                    <MenuItem value={item.name} key={`ItemNameHidden-${item.name}`} style={{ display: 'none' }}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField value={size} onChange={(e) => setSize(e.target.value)} label={'Size'} />
              <TextField
                value={note}
                onChange={(e) => setNote(e.target.value)}
                label={'Note'}
                helperText={'Specific brand, style, etc'}
              />
              <FormControl style={{ marginTop: '20px' }}>
                <FormLabel component={'legend'} color={'secondary'}>
                  Item Privacy
                </FormLabel>
                <RadioGroup
                  value={visibleToConnections}
                  onChange={() => setVisibleToConnections(!visibleToConnections)}
                  style={{ display: 'flex', flexFlow: 'row wrap' }}
                >
                  <FormControlLabel value={true} control={<Radio />} label={'Connections Only'} />
                  <FormControlLabel value={false} control={<Radio />} label={'Private'} />
                </RadioGroup>
              </FormControl>
              {(!!item || formDirty) && (
                <>
                  <Divider style={{ margin: '20px 0px' }} />
                  <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {!!item && (
                      <IconButton style={{ color: theme.palette.error.main }} onClick={() => setDeleteDialogOpen(true)}>
                        <Delete />
                      </IconButton>
                    )}
                    {!!item && formDirty && (
                      <Button size={'large'} color={'secondary'} variant={'contained'} onClick={onSubmit}>
                        Save
                      </Button>
                    )}

                    {!item && formDirty && (
                      <Button size={'large'} color={'secondary'} variant={'contained'} onClick={onSubmit}>
                        Add
                      </Button>
                    )}
                  </CardActions>
                </>
              )}
            </>
          </Card>
          <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} fullWidth>
            <DialogTitle>{`Are you sure you want to remove ${editableItem.name}?`}</DialogTitle>
            <DialogContent>
              <DialogContentText>This action is non-reversible.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color={'secondary'}
                variant={'contained'}
                onClick={() => setDeleteDialogOpen(false)}
              >{`Cancel`}</Button>
              <Button
                color={'primary'}
                variant={'contained'}
                onClick={onRemoveItem}
              >{`Remove ${editableItem.name}`}</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  )
}
