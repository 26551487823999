import { Button, Card, Divider, Typography } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import React from 'react'

export const FeedbackSuccess = () => {
  return (
    <>
      <Helmet>
        <title>Thank You! | Sizably</title>
      </Helmet>
      <Card style={{ margin: '30px', padding: '20px' }}>
        <Typography variant={'h4'} color={'primary'}>
          Thank You!
        </Typography>
        <Divider style={{ marginBottom: '20px' }} />
        <Typography variant={'body1'}>Your feedback has been successfully submitted.</Typography>
        <Button href={'/dashboard'} variant={'outlined'} color={'secondary'} style={{ marginTop: '20px' }}>
          Back to Dashboard
        </Button>
      </Card>
    </>
  )
}
