import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Profile } from '../components/cards/Profile'
import { Connections } from '../components/cards/Connections'
import { SizesList } from '../components/cards/SizesList'
import { AddItemCard } from '../components/cards/AddItemCard'
import { IncomingRequests } from '../components/cards/IncomingRequests'
import { firestore, signInWithGoogle } from '../utils/firebase'
import { useFirebaseViewer } from '../hooks/useFirebaseViewer'
import { DependentsCard } from '../components/cards/DependentsCard'
import { SearchCard } from '../components/cards/SearchCard'
import { Link } from 'react-router-dom'
import { Card, Typography, useTheme } from '@material-ui/core'

export const Dashboard = () => {
  const firebaseViewer = useFirebaseViewer()
  const theme = useTheme()

  useEffect(() => {
    ;(async () => {
      if (firebaseViewer) {
        const userRecordExists = (await firestore.collection('users').doc(firebaseViewer.uid).get()).exists

        if (!userRecordExists) {
          await firestore.collection('users').doc(firebaseViewer.uid).set({
            displayName: firebaseViewer.displayName,
            searchable: true,
            photoURL: firebaseViewer.photoURL,
            uid: firebaseViewer.uid,
            email: firebaseViewer.email,
            personalBio: '',
          })
        }
      }
    })()
  }, [firebaseViewer])

  return (
    <>
      <>
        <Helmet>
          <title>Dashboard | Sizably</title>
        </Helmet>
        <div>
          {!firebaseViewer && (
            <>
              <Card style={{ margin: '30px', padding: '20px' }}>
                <Typography variant={'h4'} color={'primary'} style={{ textAlign: 'center' }}>
                  <Link to={'#'} onClick={() => signInWithGoogle()} style={{ color: theme.palette.secondary.main }}>
                    Sign In
                  </Link>
                  {` to view your dashboard`}
                </Typography>
              </Card>
            </>
          )}
          {!!firebaseViewer && (
            <>
              <IncomingRequests />
              <SearchCard />
              <Connections />
              <AddItemCard />
              <DependentsCard />
              <SizesList />
              <Profile />
            </>
          )}
        </div>
      </>
    </>
  )
}
