import { firestore } from '../utils/firebase'

export const requestDependentLink = async (viewerUid: string, connectionId: string) => {
  Promise.all([
    firestore.collection(`users`).doc(viewerUid).collection('outgoingDependentLinkRequests').add({ uid: connectionId }),
    firestore
      .collection(`users`)
      .doc(connectionId)
      .collection('incomingDependentLinkRequests')
      .add({ uid: viewerUid, ignored: false }),
  ])
}

export const acceptDependentLinkRequest = async (
  viewerUid: string,
  viewerIncomingDependentLinkRequests: any[],
  uidToAccept: string
) => {
  const incomingRequest = viewerIncomingDependentLinkRequests.find((req: any) => req.uid === uidToAccept)
  const outgoingRequestSnapshot = await firestore
    .collection(`users/${uidToAccept}/outgoingDependentLinkRequests`)
    .where('uid', '==', viewerUid)
    .get()

  const outgoingRequestDoc = outgoingRequestSnapshot.docs.find((x) => !!x)
  const outgoingRequest = { ...outgoingRequestDoc?.data(), id: outgoingRequestDoc?.id }

  const promises: any[] = [
    firestore.collection(`users/${viewerUid}/dependentLinks`).add({ uid: uidToAccept }),
    firestore.collection(`users/${uidToAccept}/dependentLinks`).add({ uid: viewerUid }),
    firestore.collection(`users/${viewerUid}/incomingDependentLinkRequests`).doc(incomingRequest.id).delete(),
  ]

  if (outgoingRequest) {
    promises.push(
      firestore.collection(`users/${uidToAccept}/outgoingDependentLinkRequests`).doc(outgoingRequest.id).delete()
    )
  }

  await Promise.all(promises)
}

export const denyDependentLinkRequest = async (
  viewerUid: string,
  viewerIncomingDependentLinkRequests: any[],
  uidToDeny: string
) => {
  const incomingRequest = viewerIncomingDependentLinkRequests.find((req: any) => req.uid === uidToDeny)
  const outgoingRequestSnapshot = await firestore
    .collection(`users/${uidToDeny}/outgoingDependentLinkRequests`)
    .where('uid', '==', viewerUid)
    .get()

  const outgoingRequestDoc = outgoingRequestSnapshot.docs.find((x) => !!x)
  const outgoingRequest = { ...outgoingRequestDoc?.data(), id: outgoingRequestDoc?.id }

  await Promise.all([
    firestore.collection(`users/${viewerUid}/incomingDependentLinkRequests`).doc(incomingRequest.id).delete(),
    firestore.collection(`users/${uidToDeny}/outgoingDependentLinkRequests`).doc(outgoingRequest.id).delete(),
  ])
}

export const removeDependentLink = async (viewerUid: string, viewerDependentLinks: any[], uidToRemove: string) => {
  const myConnection = viewerDependentLinks.find((req: any) => req.uid === uidToRemove)
  const theirConnectionSnapshot = await firestore
    .collection(`users/${uidToRemove}/dependentLinks`)
    .where('uid', '==', viewerUid)
    .get()
  const theirConnectionDoc = theirConnectionSnapshot.docs.find((x) => !!x)
  const theirConnection = { ...theirConnectionDoc?.data(), id: theirConnectionDoc?.id }

  await Promise.all([
    firestore.collection(`users/${viewerUid}/dependentLinks`).doc(myConnection.id).delete(),
    firestore.collection(`users/${uidToRemove}/dependentLinks`).doc(theirConnection.id).delete(),
  ])
}
