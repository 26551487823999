import { Typography, List, ListItem, ListItemIcon, ListItemText, useTheme, Avatar } from '@material-ui/core'
import React from 'react'
import { Link, Link as RouterLink } from 'react-router-dom'
import { auth, signInWithGoogle, signOut } from '../utils/firebase'
import { useFirebaseViewer } from '../hooks/useFirebaseViewer'

function ListItemLink(props: any) {
  const { icon, primary, to } = props

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref: any) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to]
  )

  return (
    <ListItem button component={renderLink} style={{ flex: 1, textAlign: 'center' }}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} style={{ color: props.color, whiteSpace: 'nowrap' }} />
    </ListItem>
  )
}

export const Header = (props: any) => {
  const firebaseViewer = useFirebaseViewer()
  const theme = useTheme()

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        padding: '20px',
        backgroundColor: 'white',
      }}
    >
      <Link to={'/'} style={{ textDecoration: 'none' }}>
        <Typography variant='h4' noWrap color={'secondary'} style={{ textAlign: 'center', marginTop: '8px' }}>
          Sizably
        </Typography>
        <Typography
          variant={'body1'}
          noWrap
          color={'secondary'}
          style={{ textAlign: 'center', margin: '0px', fontSize: '.85rem' }}
        >
          Size Matters
        </Typography>
      </Link>
      <List style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'flex-end' }}>
        <ListItemLink primary={'Home'} to='/home' color={theme.palette.secondary.main} />
        {!!auth?.currentUser && (
          <ListItemLink primary={'Dashboard'} to='/dashboard' color={theme.palette.secondary.main} />
        )}
        <ListItemLink primary={'Leave Feedback'} to='/leaveFeedback' color={theme.palette.secondary.main} />
        {firebaseViewer ? (
          <ListItem
            button
            onClick={() => {
              signOut()
            }}
            style={{ flex: 1, whiteSpace: 'nowrap' }}
          >
            {firebaseViewer?.photoURL && <Avatar src={firebaseViewer?.photoURL} style={{ marginRight: '12px' }} />}
            <ListItemText primary={'Sign Out'} style={{ color: theme.palette.secondary.main }} />
          </ListItem>
        ) : (
          <ListItem button onClick={() => signInWithGoogle()} style={{ flex: 1, whiteSpace: 'nowrap' }}>
            <ListItemText primary={'Sign In'} style={{ color: theme.palette.secondary.main }} />
          </ListItem>
        )}
      </List>
    </div>
  )
}
