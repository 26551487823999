import { ListItem, Typography, useTheme } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { RectangleProfileImage } from './RectangleProfileImage'

export const RectangularUserListItem = ({ user }: { user: any }) => {
  const theme = useTheme()

  return (
    <ListItem key={`ConnectionListItem-${user.uid}`} style={{ width: '160px' }}>
      <Link to={`/publicProfile/${user.uid}`} style={{ textDecoration: 'none', width: '100%' }}>
        <RectangleProfileImage user={user} />
        <Typography variant={'body1'} style={{ color: theme.palette.secondary.main, textAlign: 'center' }}>
          {user.displayName}
        </Typography>
      </Link>
    </ListItem>
  )
}
