import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import React, { useState } from 'react'
import { AddDependentItemForm } from './AddDependentItemForm'

export const DependentItemListItem = ({
  item,
  dependent,
  isExternalItem,
}: {
  item: { id: string; name: string; note: string; size: string; visibleToConnections: boolean }
  dependent: any
  isExternalItem?: boolean
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false)

  const { id, name, note, size, visibleToConnections } = item

  return (
    <>
      <ListItem key={`SizeListItem-${id}`} style={{ width: '250px', alignSelf: 'flex-start' }}>
        <Card style={{ display: 'flex', justifyContent: 'space-between', padding: '12px', width: '100%' }}>
          <div>
            <ListItemText primary={name} secondary={`${size}`} />
            <Typography variant={'body2'} style={{ fontStyle: 'italic' }}>
              {note}
            </Typography>
            {!isExternalItem && (
              <FormControl style={{ marginTop: '12px' }}>
                <FormControlLabel
                  control={<Checkbox checked={visibleToConnections} color={'primary'} />}
                  label={'Visible To Connections'}
                />
              </FormControl>
            )}
          </div>
          {!isExternalItem && (
            <>
              <div>
                <IconButton onClick={() => setEditModalOpen(true)} color={'primary'}>
                  <Edit />
                </IconButton>
              </div>
            </>
          )}
        </Card>
      </ListItem>
      <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <Card style={{ display: 'flex', flexFlow: 'column nowrap', padding: '20px', margin: '30px' }}>
          <AddDependentItemForm item={item} dependent={dependent} onAfterSubmit={() => setEditModalOpen(false)} />
        </Card>
      </Modal>
    </>
  )
}
