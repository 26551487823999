import { firestore } from '../utils/firebase'

export const getDependentsByOwnerUid = async (viewerUid: string, dependentParentUid: string) => {
  let dependentData: any[] = []

  const canViewerAccessDependents = await (
    await firestore.collection(`users/${dependentParentUid}/connections`).where('uid', '==', viewerUid).get()
  ).docs.length

  if (canViewerAccessDependents || viewerUid === dependentParentUid) {
    let dependents = (
      await firestore
        .collection(`users/${dependentParentUid}/dependents`)
        .where('visibleToConnections', '==', true)
        .get()
    ).docs.map((dep) => ({
      ...dep.data(),
      id: dep.id,
    }))

    dependentData = await Promise.all(
      dependents.map(async (dependent) => {
        const items = await (
          await firestore
            .collection(`users/${dependentParentUid}/dependents/${dependent.id}/items`)
            .where('visibleToConnections', '==', true)
            .get()
        ).docs.map((doc) => doc.data())
        return { ...dependent, items }
      })
    )
  }

  return dependentData
}
