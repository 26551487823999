import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyAMNSlp3SsvsRCXOKgLCKyruiBi-Sn7ACs',
    authDomain: 'what-size.firebaseapp.com',
    databaseURL: 'https://what-size.firebaseio.com',
    projectId: 'what-size',
    storageBucket: 'what-size.appspot.com',
    messagingSenderId: '561613474522',
    appId: '1:561613474522:web:0c6a12b5f8774044e533e3',
    measurementId: 'G-BER0H1D7DL',
}

firebase.initializeApp(firebaseConfig)

export const analytics = firebase.analytics()
export const firestore = firebase.firestore()
export const auth = firebase.auth()

const googleProvider = new firebase.auth.GoogleAuthProvider()
googleProvider.setCustomParameters({
    prompt: 'select_account',
})
export const signInWithGoogle = () => {
    auth.signInWithRedirect(googleProvider)
}

export const signOut = () => {
    auth.signOut()
}
