import React, { useState } from 'react'
import firebase from 'firebase'
import {
  Button,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core'
import { useFirebaseViewer } from '../hooks/useFirebaseViewer'
import { firestore } from '../utils/firebase'
import { dependentOptions } from '../utils/Dependents'
import { Delete } from '@material-ui/icons'

export const AddDependentForm = ({ dependent }: any) => {
  const [category, setCategory] = useState(dependent?.category ?? '')
  const [name, setName] = useState(dependent?.name ?? '')
  const [dependentBio, setDependentBio] = useState(dependent?.bio ?? '')
  const [visibleToConnections, setVisibleToConnections] = useState(dependent?.visibleToConnections ?? true)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const firebaseViewer = useFirebaseViewer()
  const theme = useTheme()

  const onSubmit = async () => {
    if (category?.trim() && name?.trim()) {
      if (!dependent) {
        firestore.collection(`users/${firebaseViewer?.uid}/dependents`).add({
          category: category?.trim(),
          name: name?.trim(),
          bio: dependentBio?.trim(),
          visibleToConnections,
          ownerUid: firebaseViewer?.uid,
          timestamp: firebase.firestore.Timestamp.now(),
        })

        clearForm()
      } else {
        firestore.collection(`users/${dependent?.ownerUid}/dependents`).doc(dependent.id).update({
          category: category?.trim(),
          name: name?.trim(),
          bio: dependentBio?.trim(),
          visibleToConnections,
        })
      }
    }
  }

  const onRemoveDependent = async () => {
    const itemDocs = await firestore.collection(`users/${dependent?.ownerUid}/dependents/${dependent.id}/items`).get()
    itemDocs.forEach((doc) => doc.ref.delete())

    firestore.collection(`users/${dependent?.ownerUid}/dependents`).doc(dependent.id).delete()
  }

  const clearForm = () => {
    setCategory(dependent?.category ?? '')
    setName(dependent?.name ?? '')
    setDependentBio(dependent?.bio ?? '')
    setVisibleToConnections(dependent?.visibleToConnections ?? true)
  }

  const formDirty = dependent
    ? dependent?.category !== category?.trim() ||
      dependent?.name !== name?.trim() ||
      dependent?.bio !== dependentBio?.trim() ||
      dependent?.visibleToConnections !== visibleToConnections
    : category?.trim().length || name?.trim().length || dependentBio?.trim().length || !visibleToConnections

  return (
    <>
      {firebaseViewer && (
        <>
          <Typography variant={'h4'} color={'primary'}>
            {dependent ? `Edit ${dependent?.name}'s Profile` : `Add New Dependent`}
          </Typography>
          <Divider style={{ marginBottom: '20px' }} />
          {!dependent && (
            <Typography variant={'body1'} style={{ textAlign: 'left' }} color={'textSecondary'}>
              {`The purpose of this is to add dependents that you have in your family. This could be children, cats, dogs, etc. (If your dependant is not in this list, please leave feedback letting us know, and it will be added!). You can then link your list of dependents and edit permissions with your household, and the dependents will appear on all linked profiles.`}
            </Typography>
          )}
          <FormControl style={{ marginTop: dependent ? '0px' : '20px' }}>
            <InputLabel htmlFor={'Dependant-Select'}>Dependent Type</InputLabel>
            <Select id={'Dependant-Select'} value={category} style={{ textAlign: 'left' }}>
              {dependentOptions.map((dependentOption) => (
                <MenuItem
                  value={dependentOption.name}
                  style={{ marginLeft: '20px' }}
                  key={`ItemName-${dependentOption.name}`}
                  onClick={() => setCategory(dependentOption.name)}
                >
                  {dependentOption.name}
                </MenuItem>
              ))}
              {dependentOptions.map((dependentOption) => (
                <MenuItem
                  value={dependentOption.name}
                  key={`ItemNameHidden-${dependentOption.name}`}
                  style={{ display: 'none' }}
                >
                  {dependentOption.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField value={name} onChange={(e) => setName(e.target.value)} label={'Name'} />
          <FormControl style={{ marginTop: '20px' }}>
            <Typography variant={'body1'} color={'textSecondary'}>
              {name ? `${name}'s Bio` : `Dependent's Bio`}
            </Typography>
            <TextareaAutosize
              value={dependentBio}
              onChange={(e) => setDependentBio(e.target.value)}
              style={{ marginBottom: '12px', width: '100%', padding: '12px' }}
              rowsMin={5}
              required={true}
            />
          </FormControl>
          <FormControl style={{ marginTop: '20px' }}>
            <FormLabel component={'legend'} color={'secondary'}>
              Dependent Privacy
            </FormLabel>
            <RadioGroup
              value={visibleToConnections}
              onChange={() => setVisibleToConnections(!visibleToConnections)}
              style={{ display: 'flex', flexFlow: 'row wrap' }}
            >
              <FormControlLabel value={true} control={<Radio />} label={'Connections Only'} />
              <FormControlLabel value={false} control={<Radio />} label={'Private'} />
            </RadioGroup>
          </FormControl>
          {(formDirty || !!dependent) && (
            <>
              <Divider style={{ margin: '20px 0px' }} />
              <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {!!dependent && (
                  <IconButton style={{ color: theme.palette.error.main }} onClick={() => setDeleteDialogOpen(true)}>
                    <Delete />
                  </IconButton>
                )}
                {formDirty && (
                  <Button size={'large'} color={'secondary'} variant={'contained'} onClick={onSubmit}>
                    {dependent ? 'Save' : 'Add'}
                  </Button>
                )}
              </CardActions>
            </>
          )}
          <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} fullWidth>
            <DialogTitle>{`Are you sure you want to remove ${name}?`}</DialogTitle>
            <DialogContent>
              <DialogContentText>This action is non-reversible.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color={'secondary'}
                variant={'contained'}
                onClick={() => setDeleteDialogOpen(false)}
              >{`Cancel`}</Button>
              <Button color={'primary'} variant={'contained'} onClick={onRemoveDependent}>{`Remove ${name}`}</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  )
}
