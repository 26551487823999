import React from 'react'
import { Card, Divider, Typography } from '@material-ui/core'
import { useFirebaseViewer } from '../../hooks/useFirebaseViewer'
import { Search } from '../Search'
import { SEARCH_TYPES } from '../SearchListItem'

export const SearchCard = () => {
  const firebaseViewer = useFirebaseViewer()

  return (
    <>
      {firebaseViewer && (
        <Card
          style={{
            maxWidth: '100vw',
            margin: '30px',
            display: 'flex',
            flexFlow: 'column nowrap',
            padding: '20px',
          }}
        >
          <>
            <Typography variant={'h4'} color={'primary'}>
              Find Connections
            </Typography>
            <Divider style={{ marginBottom: '20px' }} />
            <Search searchType={SEARCH_TYPES.CONNECTIONS} />
          </>
        </Card>
      )}
    </>
  )
}
